<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">Artigos</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item"><a href="#">Home</a></li>
                     <li class="breadcrumb-item active">Dashboard</li>
                     <li class="breadcrumb-item active">Artigos</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- /.row -->
            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <div class="card-header">
                        <router-link
                           :to="{ name: 'articlesCreate' }"
                           class="btn btn-success"
                        >
                           <i class="fas fa-plus-circle"></i> Cadastrar Artigo
                        </router-link>

                        <div class="card-tools">
                           <div class="input-group input-group-sm pt-2" style="width: 350px;">
                              <input type="text" name="table_search" class="form-control float-right" placeholder="Buscar">

                              <div class="input-group-append">
                                 <button type="submit" class="btn btn-default">
                                    <i class="fas fa-search"></i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                           <thead>
                           <tr>
                              <th>Titulo</th>
                              <th>Data</th>
                              <th>Tipo/Seções</th>
                              <th>Estado</th>
                              <th>Restrição</th>
                           </tr>
                           </thead>
                           <tbody>
                              <tr>
                                 <td>
                                    <div class="row">
                                       <div class="col-4">
                                          <div class="timeline-item">
                                             <div class="timeline-body">
                                                <img src="https://placehold.it/150x100" alt="...">
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-8">
                                          <p class="h5"> Os malefícios do excesso de alumínio</p>
                                          <span class="text-gray">Antônio Claudio Santos das Neves | <i class="fas fa-comments"></i> 30</span>
                                       </div>
                                    </div>
                                 </td>
                                 <td>25/02/2023 15:23</td>
                                 <td>
                                    <div class="row text-primary" style="cursor: pointer"><i class="pt-1 fas fa-pencil-alt"></i> <p class="ml-2 text-bold">Editar</p></div>
                                    <p class="row text-gray text-bold">Dicas de Saúde</p>
                                 </td>
                                 <td><button class="btn btn-outline-warning btn-sm">Rascunho</button></td>
                                 <td><button class="btn btn-outline-danger btn-sm"><i class="fas fa-lock"></i> Pago</button></td>
                              </tr>
                              <tr>
                                 <td>
                                    <div class="row">
                                       <div class="col-4">
                                          <div class="timeline-item">
                                             <div class="timeline-body">
                                                <img src="https://placehold.it/150x100" alt="...">
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-8">
                                          <p class="h5"> A importância do ferro para a saúde</p>
                                          <span class="text-gray">Antônio Claudio Santos das Neves | <i class="fas fa-comments"></i> 2</span>
                                       </div>
                                    </div>
                                 </td>
                                 <td>25/02/2023 15:23</td>
                                 <td>
                                    <div class="row text-primary" style="cursor: pointer"><i class="pt-1 fas fa-pencil-alt"></i> <p class="ml-2 text-bold">Editar</p></div>
                                    <p class="row text-gray text-bold">Dicas de Saúde</p>
                                 </td>
                                 <td><button class="btn btn-outline-primary btn-sm">Não publicado</button></td>
                                 <td><button class="btn btn-outline-danger btn-sm"><i class="fas fa-lock"></i> Pago</button></td>
                              </tr>
                              <tr>
                                 <td>
                                    <div class="row">
                                       <div class="col-4">
                                          <div class="timeline-item">
                                             <div class="timeline-body">
                                                <img src="https://placehold.it/150x100" alt="...">
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-8">
                                          <p class="h5"> Afta na boca: Causas e tratamento</p>
                                          <span class="text-gray">Antônio Claudio Santos das Neves | <i class="fas fa-comments"></i> 10</span>
                                       </div>
                                    </div>
                                 </td>
                                 <td>25/02/2023 15:23</td>
                                 <td>
                                    <div class="row text-primary" style="cursor: pointer"><i class="pt-1 fas fa-pencil-alt"></i> <p class="ml-2 text-bold">Editar</p></div>
                                    <p class="row text-gray text-bold">Saúde Bucal</p>
                                 </td>
                                 <td><button class="btn btn-outline-success btn-sm">Publicado</button></td>
                                 <td><button class="btn btn-outline-success btn-sm"><i class="fas fa-lock-open"></i> Grátis</button></td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <!-- /.card-body -->

                     <div class="card-footer clearfix">
                        <ul class="pagination pagination-sm m-0 float-right">
                           <li class="page-item"><a class="page-link" href="#">&laquo;</a></li>
                           <li class="page-item"><a class="page-link" href="#">1</a></li>
                           <li class="page-item"><a class="page-link" href="#">2</a></li>
                           <li class="page-item"><a class="page-link" href="#">3</a></li>
                           <li class="page-item"><a class="page-link" href="#">&raquo;</a></li>
                        </ul>
                     </div>
                     <!-- /.card-footer -->
                  </div>
                  <!-- /.card -->
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
export default {
   name: "Articles"
}
</script>

